import React from 'react'
// import { Job } from '../components/Job'
// import { SEO } from '../components/SEO'
// import { JobLayout } from '../layouts/JobLayout'
import { Job as JobType } from '../../utils/greenhouse'
import JobPageTemplate from '../../templates/JobPageTemplate'

const ProductDesigner = () => {
  const job: JobType = {
    id: '2',
    title: "Product Designer (UX/UI)",
    location: {
        name: "Poznań/Remote"
    },
    metadata: [
      {
        value: {
          min_value: "18000",
          max_value: "28000",
          unit: "PLN"
        },
        value_type: "",
        name: "Salary Range"
      }
    ],
    emailApply: true,
    ogImage: "https://jobs.fourthwall.com/images/og/ux.png",
    content: `
      <div class="article">
        <div>
          <p><span style="font-weight: 400;">Fourthwall helps creative people launch brands and sell online. Our platform currently powers over 100,000 content creators, from superstars earning millions to up-and-comers looking for their first sale. Our mission is to make launching a brand as easy as having a great idea while Fourthwall handles as much of the logistics as we can (we help our creators with manufacturing, website building, shipping, customer support, taxes, and more – all via automation and technology).</span></p>
          <p><span style="font-weight: 400;">When you see your favorite YouTuber or creator launch their merchandise, there's a good chance they're using Fourthwall. Here are a few examples: <a href="https://mkbhd.com" target="_blank">MKBHD.com</a>, <a href="https://sour.gg" target="_blank">Sour.gg</a>, <a href="https://beautifulbastard.com" target="_blank">BeautifulBastard.com</a>, <a href="https://neffsauce.com" target="_blank">NeffSauce.com</a>, <a href="https://madsmitch.com" target="_blank">MadsMitch.com</a>, <a href="https://vivaplus.tv" target="_blank">VivaPlus.tv</a>.</span></p>
        </div>

        <div>
          <p><strong>The Role</strong></p>
          <p><span style="font-weight: 400;">We’re looking for an exceptional product designer to join our team as the second designer in the company. You’ll work in an environment where we believe in building beautiful, high-quality products to help our users succeed, collaborating with fantastic product leaders and engineers (averaging 13+ years of experience).</span></p>
          <p><span style="font-weight: 400;">Our platform goes beyond simple storefronts – we manage complex end-to-end processes, including manufacturing and logistics, memberships, marketing tools, and more. Our challenge as designers is figuring out how to take complex processes, like designing and manufacturing products, and figuring out how to simplify those into user experiences that non-experts intuitively understand.</span></p>
          <p><span style="font-weight: 400;">Our standard for design and UX is extremely high and we pride ourselves on being great at what we do. As a designer at Fourthwall, you’ll be the owner of major parts of our product and be able to directly help tens of thousands of creative people earn a living from doing what they love.</span></p>
        </div>

        <div>
          <p><strong>You'll Love This Role If...</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">You care deeply about being great at what you do</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">You want to shape the product, not just deliver project-based designs</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">You move fast and are comfortable working on multiple projects at once</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">You appreciate working with experienced product leaders and engineers who understand design principles</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">You expect your designs to be implemented in a pixel-perfect way</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">You like to work independently and don’t need constant oversight</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">You’re comfortable with receiving honest feedback and being pushed to do your best work</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">You're excited about helping thousands of creative people earn a living doing what they love</span></li>
          </ul>
        </div>

        <div>
          <p><strong>Requirements</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Exceptional talent as a designer</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">A strong portfolio that demonstrates your talent</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Ability to own projects end-to-end, from concept to UX to UI</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Ability to work with product leaders, engineers, and other stakeholders collaboratively</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Ability to work independently</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Figma expertise</span></li>
          </ul>
        </div>

        <div>
          <p><strong>The Benefits</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Salary 18,000 - 28,000 PLN + VAT (paid in USD, with VAT 0% for service export to the US)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Equity in our US-based company</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Remote work with flexible hours (EU timezone)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">26 days of paid vacation annually</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Health benefits (LUX MED + dental care)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Professional development budget</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Latest hardware and software</span></li>
          </ul>
        </div>
      
        <div>
          <p><strong>The Interview Process</strong></p>
          <ol>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Portfolio review</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Initial discussions with our design team</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Paid design challenge to see how we work together</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Interviews with other team members outside of design</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Final conversation about role specifics and expectations</span></li>
          </ol>
        </div>

        <div>
          <p><strong>Join Us</strong></p>
          <p><span style="font-weight: 400;">We’re looking for a designer who wants to be the co-owner of a product at scale. Someone who cares deeply about the quality of their work and makes an impact.</span></p>
          <p><span style="font-weight: 400;">If that sounds like you, we'd love to review your portfolio and hear why you're interested in Fourthwall. We review every application.</span></p>
          <p><span style="font-weight: 400;">Just so you know, if you meet most but not all of our requirements, we still encourage you to apply. We value potential and attitude as much as experience.</span></p>
        </div>

      </div>
    `
  }

  return (
    <div>
      <JobPageTemplate pageContext={{
          job
      }} />
    </div>
  )
}

export default ProductDesigner